@import "breakpoint";
@import "settings";
@import "foundation/components/global"; // *always required
@import "foundation/components/grid";



.entity-gallery-items {
  width: 100%;

  @include breakpoint($large-mobile) {
    width: calc(50% - 20px);
  }

  @include breakpoint($desktop) {
    width: calc(33.333333% - 20px);
  }
  margin-bottom: 20px;
  position: relative;

  .gallery-overlay-holder {
    @include breakpoint($desktop) {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.75);
      padding: 10px;
      cursor: pointer;
    }
  }
  .gallery-overlay {
    @include breakpoint($desktop) {
      border: 1px solid white;
      height: 100%;
    }
  }

  .gallery-text {
    @include breakpoint($desktop) {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      color: white;
      font-size: 22px;
      text-align: center;
    }
    padding: 5px 0;

    @include breakpoint($desktop) {
      padding: 5px 10px;
    }
  }
}
.gallery-overlay-holder {
  @include breakpoint($desktop) {
    opacity: 0;
    transition: opacity 0.3s;
  }
}
.hovered .gallery-overlay-holder {
  opacity: 1;
  transition: opacity 0.3s;
}
